html, body {
    margin: 0;
    padding: 0;
}
#topmenu {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    @include for_size(tpu) {
        flex-direction: row;
    }
    justify-content: space-between;
    align-items: center;
    padding: 0.2em;
    background-color: $white;
    #logo {
        height: 2.8em;
        width: auto;
    }    
    a {
        margin: 0.5em 2em;
        @include for_size(tpu) {
            margin: 0 2em;
        }
    }
    @include shadow-under();
}
#topmenu.mobile {
    height: 3em;
    a {
        display: none;
        @include for_size(tlu) {
            display: inline-block;
        }
    }
}
#menuToggle {
    position: absolute;
    top: 0.1em;
    right: 0.5em;
    font-size: 1.5em;
    @include for_size(tlu) {
        display: none;
    }    
}
#container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    margin: 0;
    padding: 0;
    @include for_size(tlu) {
        overflow: hidden;
        overflow-y: auto;
        perspective: 2px;        
    }
}

section {
    background-size: cover;
    color: $white;   
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;  
    &.image {
        background-position: center center;
        background-image: var(--background);
        background-repeat: no-repeat;
        height: 45vh;
        @include for_size(tlu) {
            z-index: -1;
            height: 100vh;
            transition: all 0.7s ease-out;
            transform: translateZ(-1px) scale(1.5);            
        }
        &#top {
            background-position: left center;
        }
    }
    &.text {
        color: $black;
        @include for_size(tpu) {
            color: transparent;   
            min-height: 50vh;         
        }
        transition: all 0.7s ease-out;
        transition-delay: 0.1s;  
        flex-direction: column; 
        text-align: left;
        background-color: $white;
        @include shadow-around();
        padding: 5em 0;
        &.scrollit {
            color: $black;
        }
        .content {
            margin: 1em auto;
            line-height: 1.5em;
            font-size: 1.2em;
            width: 90%;
            max-width: $content-max-width;        
        }
    }
    &.heading {
        z-index: -1;
        @include for_size(tpu) {
            transform: translateY(-30vh) translateZ(1px) scale(1.5);
        }
      }
} 
.logo {
    position: absolute;    
    @include for_size(tpu) {
        position: relative;
        transform: translateY(-5vh) translateZ(1px) scale(0.5);
    }    
    svg {
        width: 100%;
        height: auto;
        max-width: 500px;
        filter: drop-shadow(1px 1px 3px rgb(255 255 255 / 0.4));
    }
}
.columns {
    display: flex;
    flex-direction: column;
    @include for_size(tpu) {
        flex-direction: row;
    }
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
    div {
        flex: 1;
        a {
            display: block;
            &.btn {
                @include buttonStyle();
            }
        }
    }
}

#anfahrt {
    iframe {
        width: 100%;
    }
}
#intro {
    text-align: center;
    h1 {
        margin-bottom: 0;
    }
    h2 {
        margin-top: 0;
    }
}
#photos {
    div.images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0.2em;
        a {            
            aspect-ratio: 4 / 3;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            @include for_size(tpu) {
                flex: 1 1 30%;
            }
        }
    }
}
#biosthetique, #impressum, #einblicke {
    background-color: $black;
    color: $white;
    a {
        color: $white;
    }
}
#einblicke {
    background-color: rgb(30,30,30);
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include for_size(tpu) {
            flex-direction: row;
        }
        gap: 1em;
        iframe {
            max-width: 100%;
            flex: 1;
        }        
    }
}
.ce-banner {
    display: none;
}

