.fslightbox-absoluted {
    .fslightbox-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 1em auto;
        text-align: center;
        color: $white;
    }
} 
  /* The work below, CSSBox, is released under the Creative Commons
   Attribution-ShareAlike 4.0 license and is available on
   https://github.com/TheLastProject/CSSBox. You are not required to add
   additional credit to your website, just leave the above text in this file */
div.cssbox {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    flex: 1;

    a.cssbox_link {
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: inline-block;
        box-shadow: 3px 3px 15px rgba(0,0,0,0.25);
        margin: 10px 0;
      }  
      
      span.cssbox_full {
        z-index: 999;
        position: fixed;
        display: block;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.9);
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        cursor: default;
        transition: opacity 0.2s linear;

        img {
            position: fixed;
            background-color: white;
            margin: 0;
            padding: 0;
            max-height: 90%;
            max-width: 90%;
            width: auto;
            height: 90%;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            box-shadow: 0 0 20px black;
            display: none;
        }
      }      

      a.cssbox_close,
      a.cssbox_prev,
      a.cssbox_next {
        z-index: 999;
        position: fixed;
        text-decoration: none;
        visibility: hidden;
        color: white;
        font-size: 36px;
      }
      
      a.cssbox_close {
        bottom: 1%;
        right: 1%;
        &:after {
            content: '\00d7';
        }
      }

      a.cssbox_prev,
      a.cssbox_next {
        top: 50%;
        transform: translate(0%, -50%);
      }
      
      a.cssbox_prev {
        left: 5%;
      }
      
      a.cssbox_next {
        right: 5%;
      }   
      
      a:target ~ a.cssbox_close,
      a:target ~ a.cssbox_prev,
      a:target ~ a.cssbox_next {
        visibility: visible;
      }
      
      a:target > img.cssbox_thumb + span.cssbox_full {
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
      }
      
      a:target > img.cssbox_thumb + span.cssbox_full img {
        display: inline-block;
      }      
}

  

  

  

  