/**/
$font-stack: 'Hind', 'Verdana', 'Helvetica', 'sans-serif';
$heading-font: 'Arapey', Tahoma, Helvetica, cursive;
$std-line: 150%;
$std-size: 20px;
/**/

$white: rgb(255,255,255);
$black: rgb(0,0,0);

$content-max-width: 1200px;
$layout-max-width: 1600px;
