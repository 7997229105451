/* arapey-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Arapey';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/arapey-v16-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/arapey-v16-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* hind-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/hind-v16-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/hind-v16-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* hind-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/hind-v16-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/hind-v16-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Regular.ttf'); /* For IE6-8 */
  src: local(''),
    local('../webfonts/MaterialIcons-Regular.otf') format('truetype'), 
    local('../webfonts/MaterialIconsOutlined-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialSymbols.woff2') format('woff2');
}

.material-icons {
  /**
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;  
  display: inline;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  position: relative;
  top: 0.2em;
  padding-right: 0.2em;
  **/

  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;  
  position: relative;
  top: 0.2em;
  padding-right: 0.2em;
}