@mixin for-size($size) {
  @if $size == m {
    @media (max-width: 400px) { @content; }
  } @else if $size == p {
    @media (min-width: 768px) { @content; }
  } @else if $size == mobile {
    @media screen and (max-width: 849px) {  @content; }      
  } @else if $size == tpu {
    @media (min-width: 850px) { @content; }
  } @else if $size == portatil {
    @media screen and (max-width: 1040px) { @content; }      
  } @else if $size == tlu {
    @media (min-width: 1040px) { @content; }
  } @else if $size == d {
    @media (min-width: 1199px) { @content; }
  } @else if $size == su {
    @media (min-width: 1439px) { @content; }
  } @else if $size == ssu {
    @media (min-width: 1700px) { @content; }
  } @else if $size == sdu {
    @media (min-width: 2200px) { @content; }
  }
}

@mixin shadow-under() {
  -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
  box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.5);
}

@mixin shadow-under-light() {
  -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.25);
  box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.25);
}

@mixin shadow-top() {
  -webkit-box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
  box-shadow: 0px -10px 15px -5px rgba(0,0,0,0.5);
}

@mixin shadow-right() {
  -webkit-box-shadow: 5px 0px 10px -1px rgba(0,0,0,0.2);
  -moz-box-shadow: 5px 0px 10px -1px rgba(0,0,0,0.2);
  box-shadow: 5px 0px 10px -1px rgba(0,0,0,0.2);
}

@mixin shadow-around() {
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 0 15px rgba(0,0,0,0.5);
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
}

@mixin vertical-gradient() {
  background: linear-gradient(to bottom, rgba(0, 0, 0 ,0.45) 0%,rgba(0, 0, 0 ,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin vertical-gradient-upright() {
  background: linear-gradient(to top, rgba(120,125,110 ,0.8) 0%,rgba(256, 256, 256 , 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin vertical-gradient-upright-dark() {
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(256, 256, 256 , 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin bg-gradient() {
  background: rgb(89,108,83);
  background: linear-gradient(45deg, rgba(89,108,83,1) 0%, rgba(146, 166, 140,1) 32%, rgba(89,108,83,1) 100%);
}

@mixin coloredGradient($color, $secColor) {
  background: $color;
  background: linear-gradient(45deg, $color 0%, $secColor 32%, $color 100%);
}

@mixin content-gradient() {
  background: $white;
  background: linear-gradient(0deg, $contrast 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 90%,$contrast 100%);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin img-radius() {
  border-radius: 10px;
}

@mixin buttonStyle() {
  color: $white;
  padding: 0.2em 1em;
  display: block;
  margin: 1em auto;
  text-align: center;
  font-weight: normal;
  background-color: rgb(30,30,30);
  border-radius: 10px;
  border: 1px solid $white;
  @include shadow-around();
  &:hover {
    color: $white !important;
    background-color: $black;
  }
}

@mixin headline() {
  font-family: $heading-font;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.02em;
  margin-bottom: 0.35em;
}

@mixin bgimg() {
 
}


// Mixin itself
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
