@keyframes rotateBG {
  from {
    background-position: -500px;
  }
  to {
    background-position: 500px;
  }
}

@keyframes borderShine {
  from {
    border-top: -200px;
  }
  to {
    border-top: 200px;
  }
}

@keyframes slideFromRight {
  from {
    right: -300px;
  }
  to {
    right: 20px;
  }
}

@keyframes backgroundSlide {
  from {
    background-position: 25% 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes backgroundSlide2 {
  from {
    background-position: 50% 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes bubblePop {
  from {
    transform: scale(0.1) rotate(15deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
}

.slider{
  -webkit-transition: opacity 2000s ease-in-out;
    -moz-transition: opacity 2000s ease-in-out;
    -ms-transition: opacity 2000s ease-in-out;
    -o-transition: opacity 2000s ease-in-out;
    transition: opacity 2000s ease-in-out;
     opacity: 1;
}
