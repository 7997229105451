body, html {
  padding: 0;
  margin: 0;
}

body {
  background-color: $white;
  font-family: $font-stack;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: $std-line;
  color: $black;
  @include for_size(p) {
    font-size: $std-size;
  }
}

h1, h2, h3, h4 {
  @include headline();
}

h1 {
  overflow: hidden;
  font-size: 1.8em;
  line-height: initial;
  padding-top: 0.5em;
  margin: 0;
  @include for_size(tlu) {
    font-size: 3em;
    line-height: 140%;
  }
}

h2 {
  font-size: 1.5em;
  @include for_size(tlu) {
    font-size: 2.2em;
  }
}

h3 {
  font-size: 1.2em;
  @include for_size(tlu) {
    font-size: 1.5em;
  }
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

a:link, a:visited, a:active, a:hover {
  text-decoration: none;
  color: $black;
  &.btn {
    @include buttonStyle();
  }  
}

a:active, a:focus {
  border: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

ul {
  list-style-type: square;
}

p {
  margin-top: 0;
}


